<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Editar cupón</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Codigo -->
        <vs-input @blur="coupon.code= trimString(coupon.code)" label="Código" v-model="coupon.code"
                  class="w-full" name="code"
                  v-validate="'required|min:1|max:30'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('code')">{{ errors.first('code') }}</span>

        <!-- Porcentaje -->
        <vs-input v-if="coupon.type!=='delivery'" label="Porcentaje" v-model.number="coupon.percentage"
                  class="mt-5 w-full" name="percentage"
                  v-validate="'required|numeric|min_value:1'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('percentage')">{{ errors.first('percentage') }}</span>

        <!-- Start -->
        <div class="mt-5">
          <label class="vs-select--label" for="startDate">Fecha de inicio</label>
          <datepicker label="Inicio" v-model="coupon.startDate" id="startDate"
                      v-validate="'required'" name="startDate"></datepicker>
          <span class="text-danger text-sm"
                v-show="errors.has('startDate')">{{ errors.first('startDate') }}</span>
        </div>

        <!-- End -->
        <div class="mt-5">
          <label class="vs-select--label" for="endDate">Fecha de fin</label>
          <datepicker label="Inicio" v-model="coupon.endDate" id="endDate"
                      v-validate="'required'" name="endDate"></datepicker>
          <span class="text-danger text-sm"
                v-show="errors.has('endDate')">{{ errors.first('endDate') }}</span>
        </div>

        <!--Quantity-->
        <vs-input-number class="mt-5 mb-5" label="Cantidad:" v-model.number="coupon.quantity"
                         name="quantity" v-validate="'required|min_value:0'" min="0"/>
        <span class="text-danger text-sm"
              v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
        <!--End-->

        <!--Min-->
        <vs-input type="number" v-if="coupon.type==='delivery'"
                  label="Pedido mínimo"
                  v-model.number="coupon.min"
                  class="mt-5 w-full" name="min"
                  v-validate="'required|min:1|max:30'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('min')">{{ errors.first('min') }}</span>
        <!---->

        <!--Restrictions-->
        <template v-if="coupon.type === 'restrictions'">
          <div class="mt-5">
            <label class="vs-select--label mt-5" for="includeCategories">Categorías habilitadas</label>
            <v-select name="includeCategories" id="includeCategories" multiple label="name" :options="categories"
                      v-model="coupon.categories"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('includeCategories')">{{ errors.first('includeCategories') }}</span>
          </div>
          <!--End-->

          <!--Exclude products-->
          <div class="mt-5">
            <label class="vs-select--label mt-5" for="excludedProducts">Excluir productos</label>
            <v-select name="excludedProducts" id="excludedProducts" multiple label="label" :options="products"
                      v-model="coupon.excludedProducts"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
          </div>
          <!--End-->
        </template>
        <!--End-->

        <!--Father-->
        <template v-if="coupon.type==='father'">
          <!-- Sections -->
          <div class="mt-5">
            <label class="vs-select--label" for="sections">Secciones</label>
            <v-select :reduce="section => section.name" multiple id="sections" label="name"
                      :options="highlightCategories"
                      v-model="coupon.sections"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'" name="sections"/>
          </div>
          <!--Exclude categories-->
          <div class="mt-5">
            <label class="vs-select--label mt-5" for="excludedCategories">Excluir categorías</label>
            <v-select clearable="true" name="includeCategories" id="excludedCategories" multiple label="name"
                      :options="categories"
                      v-model="coupon.categories"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
          </div>
          <!--End-->

          <!--Excluded products-->
          <div class="mt-5">
            <label class="vs-select--label mt-5" for="excludedFatherProducts">Excluir productos</label>
            <v-select clearable="true" name="excludedProducts" id="excludedFatherProducts" multiple label="label"
                      :options="products"
                      v-model="coupon.excludedProducts"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
          </div>
          <!--End-->
        </template>
        <!--End-->

        <!--Product-->
        <template v-if="coupon.type ==='product'">
          <!--Included products-->
          <div class="mt-5">
            <label class="vs-select--label mt-5" for="includedProducts">Productos</label>
            <v-select multiple clearable="true" name="includedProducts" id="includedProducts" label="label"
                      :options="products"
                      v-model="coupon.product"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
          </div>
        </template>
        <!--End-->
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Datepicker from 'vuejs-datepicker'
import trimString from "../../mixins/trimString"
import vSelect from 'vue-select'

const firebase = require("firebase")
// Required for side-effects
require("firebase/firestore")
let db = firebase.firestore()
export default {
  name: 'UpdateCoupon',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      }
    },
    products: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    highlightCategories: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker,
    vSelect
  },
  mixins: [trimString],
  data() {
    return {
      coupon: {},
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false
    }
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        this.coupon = {
          ...this.data
        }
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any()
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    submitData() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.progress = true
          const obj = {
            ...this.coupon,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            uid: firebase.auth().currentUser.uid,
          }
          obj.code = obj.code.toUpperCase()
          // New coupon
          db.collection('coupons').doc(this.coupon.id).update({
            ...obj,
          }).then(() => {
            this.$store.dispatch('coupons/updateCoupon', {coupon: obj})
            this.progress = false
            this.$vs.notify({
              color: 'success',
              title: 'Cupón',
              text: 'Cupón modificado correctamente.'
            })
            this.$emit('closeSidebar')
          }).catch(() => {
            this.progress = false
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 550px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
